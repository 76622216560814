import { useMsal } from '@azure/msal-react';

import { loginAuthRequest } from 'authConfig';

import Button from 'components/Button';

const LoginButton = () => {
  const { instance } = useMsal();

  return (
    <Button
      id="login-button"
      onClick={async () => {
        await instance.loginRedirect({
          scopes: loginAuthRequest.scopes,
          prompt: 'select_account',
        });
      }}
    >
      Login
    </Button>
  );
};

export default LoginButton;
