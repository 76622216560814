import { useMsal } from '@azure/msal-react';

import Button from 'components/Button';

const LogoutButton = () => {
  const { instance } = useMsal();

  return (
    <Button
      id="logout-button"
      onClick={async () => {
        await instance.logoutRedirect({
          account: instance.getActiveAccount(),
        });
      }}
    >
      Logout
    </Button>
  );
};

export default LogoutButton;
