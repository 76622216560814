const Cell = ({
  name,
  value,
}:{
  name: string;
  value: string;
}) => (
  <span
    className="grid"
    style={{ gridTemplateColumns: '150px 1fr' }}
  >
    <span>
      {name}
    </span>
    <span>
      {value}
    </span>
  </span>
);

const IDs = ({
  RecId,
  UserId,
  OrganisationId,
}: {
  RecId: string;
  UserId: string;
  OrganisationId: string;
}) => {
  return (
    <div className="grid gap-1">
      <Cell name="User ID" value={UserId} />
      <Cell name="Recording ID" value={RecId} />
      <Cell name="Organisation ID" value={OrganisationId} />
    </div>
  );
};

export default IDs;
