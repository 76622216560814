import Header from "components/Header";
import LoginButton from "./LoginButton";

const LoginPage = () => {
  return (
    <div className="w-full h-screen">
      <Header />
      <div className="mt-24 flex flex-col items-center gap-6">
        <LoginButton />
      </div>
    </div>
  );
};

export default LoginPage;
