import { lazy, useRef, useState, useEffect } from 'react';
import { IPublicClientApplication } from '@azure/msal-browser';
import { hideBodyScrollBar, showBodyScrollBar } from 'helpers';
import { useUser, UserProvider, InsightsProvider } from 'context';
import {
  MsalProvider,
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from '@azure/msal-react';

import Modal from 'components/Modal';
import Header from 'components/Header';
import { LoginPage } from 'components/SSO';

const Visualizer = lazy(() => import('components/Visualizer'));
const Unsubscribed = lazy(() => import('components/Unsubscribed'));

const Simulator = () => {
  const abortCtrl = useRef(new AbortController());

  const [hasMounted, setHasMounted] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [isSubscribed, setIsSubscribed] = useState<boolean | null>(null);

  const { UserId, UserPhoto, getSubscriptionStatus } = useUser();

  useEffect(() => {
    if (isModalOpen) {
      hideBodyScrollBar();
    } else {
      showBodyScrollBar();
    }
  }, [isModalOpen]);

  useEffect(() => {
    (async () => {
      if (UserId) {
        abortCtrl.current = new AbortController();

        const subscriptionStatus = await getSubscriptionStatus(abortCtrl.current);
  
        setIsSubscribed(subscriptionStatus);
  
        setHasMounted(true);
      }
    })()

    return () => { abortCtrl.current.abort(); };
  }, [
    UserId,
    setHasMounted,
    setIsSubscribed,
    getSubscriptionStatus,
  ]);

  return (
    <div className="simulator">
      <Header UserPhoto={UserPhoto} />
      {
        hasMounted && isSubscribed !== null
          ? (
            isSubscribed
              ? <Visualizer
                  setIsModalOpen={setIsModalOpen}
                />
              : <Unsubscribed />
          )
          : <></>
      }
      <Modal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
    </div>
  );
};

const App = ({ pca }: { pca: IPublicClientApplication }) => {
  return (
    <MsalProvider instance={pca}>
      <AuthenticatedTemplate>
        <UserProvider>
          <InsightsProvider>
            <Simulator />
          </InsightsProvider>
        </UserProvider>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <LoginPage />
      </UnauthenticatedTemplate>
    </MsalProvider>
  );
};

export default App;
