const Transcribing = () => {
  document.title = "Swiss German | Virtuosis"

  return (
    <iframe
      style={{
        minWidth: "100vw",
        minHeight: "100vh",
      }}
      src="https://stt4sg.fhnw.ch/record"
      title="Swiss German Transcribing"
      allow="microphone"
    />
  );
};

export default Transcribing;
