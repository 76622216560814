import { LogLevel, Configuration } from "@azure/msal-browser";

const ua = window.navigator.userAgent;
const msie = ua.indexOf("MSIE ");
const msie11 = ua.indexOf("Trident/");
const msedge = ua.indexOf("Edge/");
const firefox = ua.indexOf("Firefox");
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;
const isFirefox = firefox > 0;

export const msalConfig: Configuration = {
  auth: {
    clientId: '1fe21a44-dcf3-4406-be96-681861c25183',
    authority: 'https://login.microsoftonline.com/common',
    redirectUri: '/',
    postLogoutRedirectUri: '/',
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: isIE || isEdge || isFirefox
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {	
          return;	
        }
        switch (level) {	
          case LogLevel.Error:	
            console.error(message);	
            return;	
          case LogLevel.Info:	
            console.info(message);	
            return;	
          case LogLevel.Verbose:	
            console.debug(message);	
            return;	
          case LogLevel.Warning:	
            console.warn(message);	
            return;	
          default:
            return;
        }
      }
    }
  }
};

export const msGraphAuthRequest = {
  scopes: ['User.Read'],
};

export const virtuosisAuthRequest = {
  scopes: [
    'api://1fe21a44-dcf3-4406-be96-681861c25183/User.Manage',
  ],
};

export const loginAuthRequest = {
  scopes: [
    ...msGraphAuthRequest.scopes,
    ...virtuosisAuthRequest.scopes
  ],
};
