import {
  useState,
  useEffect,
  useContext,
  useCallback,
  createContext,
  PropsWithChildren,
} from 'react';
import * as Sentry from '@sentry/react';
import { useMsal } from "@azure/msal-react";
import { SilentRequest, AuthenticationResult } from '@azure/msal-browser';

import { User } from 'types';
import { apiBaseUrl } from 'helpers';
import {
  loginAuthRequest,
  msGraphAuthRequest,
  virtuosisAuthRequest,
} from 'authConfig';

interface UserContextTypes {
  UserId: User["uniqueId"];
  UserPhoto: User["photo"];
  OrganisationId: User["tenantId"];
  getSASToken: (abortCtrl?: AbortController) => Promise<string>;
  getAuthToken: (request: SilentRequest) => Promise<AuthenticationResult | undefined>
  getSubscriptionStatus: (abortCtrl?: AbortController) => Promise<boolean | null>;
}

const UserContext = createContext<UserContextTypes>({
  UserId: '',
  UserPhoto: '',
  OrganisationId: '',
  getSASToken() { return new Promise(() => {})},
  getAuthToken() { return new Promise(() => {})},
  getSubscriptionStatus() { return new Promise(() => {})},
});

export const useUser = () => useContext(UserContext);

export const UserProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const [UserId, setUserId] = useState<User["uniqueId"]>('');

  const [UserPhoto, setUserPhoto] = useState<User["photo"]>('');

  const [OrganisationId, setOrganisationId] = useState<User["tenantId"]>('');

  const { instance } = useMsal();

  const getAuthToken = useCallback(async (request: SilentRequest) => {
    try {
      return await instance.acquireTokenSilent(request);
    } catch (err: any) {
      const tokenErrors = [
        "invalid_grant",
        "login_required",
        "consent_required",
        "interaction_required",
      ];

      if (tokenErrors.includes(err?.errorCode?.toLowerCase())) {
        try {
          await instance.acquireTokenRedirect(loginAuthRequest);
        } catch (redirectError) {
          console.log(redirectError);
        }
      }
    }
  }, [instance]);

  const getSASToken = useCallback(async (abortCtrl?: AbortController) => {
    try {
      const token = await getAuthToken(virtuosisAuthRequest);

      if (!token) {
        throw new Error('Failed to get auth token')
      }

      const { accessToken } = token;

      const res = await fetch(
        `${apiBaseUrl}/analytics-pipeline/v1.0/blob/sas`,
        {
          signal: abortCtrl?.signal,
          headers: { Authorization: accessToken },
        },
      );

      return await res.text();
    } catch (err) {
      Sentry.captureException(err);
      return '';
    }
  }, [getAuthToken]);

  const getSubscriptionStatus = useCallback(async (abortCtrl?: AbortController) => {
    try {
      const token = await getAuthToken(virtuosisAuthRequest);

      if (!token) {
        throw new Error('Failed to get auth token')
      }

      const { uniqueId, tenantId, accessToken } = token;

      const res = await fetch(
        'https://virtuosis-client-prod.azure-api.net/subscription/v1.0/status',
        {
          method: 'POST',
          body: JSON.stringify({ UserId: uniqueId, TenantId: tenantId }),
          headers: { Authorization: accessToken },
          signal: abortCtrl?.signal,
        },
      );
  
      const { hasLicence } = (await res.json()) || {};

      return !!hasLicence;
    } catch (err) {
      Sentry.captureException(err);
      return false;
    }
  }, [getAuthToken]);

  const setUserInfo = useCallback(async (abortCtrl?: AbortController) => {
    try {
      const token = await getAuthToken(msGraphAuthRequest);

      if (!token) {
        throw new Error('Failed to get auth token')
      }

      const { tenantId, uniqueId, accessToken } = token;

      setUserId(uniqueId);

      setOrganisationId(tenantId);

      const res = await fetch(
        'https://graph.microsoft.com/v1.0/me/photos/48x48/$value',
        {
          signal: abortCtrl?.signal,
          headers: {
            Authorization: accessToken,
            'Content-Type': 'image/jpg',
          },
        },
      );

      const blob = await res.blob();

      if (blob?.size && blob?.type === 'image/png') {
        setUserPhoto(URL.createObjectURL(blob));
      }
    } catch (err) {
      console.log(err);
    }
  }, [getAuthToken]);

  useEffect(() => {
    const abortCtrl = new AbortController();

    setUserInfo(abortCtrl);

    return () => { abortCtrl.abort(); };
  }, [setUserInfo]);

  return (
    <UserContext.Provider
      value={{
        UserId,
        UserPhoto,
        OrganisationId,
        getSASToken,
        getAuthToken,
        getSubscriptionStatus
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
