import { Loading } from 'components/Animations';

const Button: React.FunctionComponent<
  React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > & {
    title?: string;
    isLoading?: boolean;
    loadingWidth?: string;
  }
> = ({
  children,
  style,
  title,
  isLoading,
  loadingWidth,
  ...rest
}) => {
  if (isLoading) {
    return (
      <div
        className="flex justify-center items-center"
        style={{ width: loadingWidth || 'auto', height: '44px' }}
      >
        <Loading />
      </div>
    );
  }

  return (
    <button
      type="button"
      className="inline-flex items-center px-6 py-2.5 font-medium text-center text-white bg-blue-700 rounded hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
      style={style}
      {...rest}
    >
      {title ?? children}
    </button>
  );
};

export default Button;
