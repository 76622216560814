import { useEffect, StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import {
  useLocation,
  matchRoutes,
  RouterProvider,
  useNavigationType,
  createBrowserRouter,
  createRoutesFromChildren,
} from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { 
  EventType,
  EventMessage,
  AuthenticationResult,
  PublicClientApplication
} from '@azure/msal-browser';

import { msalConfig } from './authConfig';
import App, { Transcribing } from './components';

import 'tw-elements';
import 'assets/css/styles.css';

Sentry.init({
  dsn: "https://05339ef28e95904d2d1632a2281a5017@o4506237862412288.ingest.sentry.io/4506288835133440",
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      ),
    }),
  ],
  enabled: process.env.NODE_ENV === "production",
  environment: process.env.NODE_ENV, 
});

export const msalInstance = new PublicClientApplication(msalConfig);

if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

msalInstance.enableAccountStorageEvents();

msalInstance.addEventCallback((event: EventMessage) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload as AuthenticationResult;
    const account = payload.account;
    msalInstance.setActiveAccount(account);
  }
});

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const router = sentryCreateBrowserRouter([
  {
    path: "transcribing/swiss-german",
    element: <Transcribing />,
  },
  {
    path: "*",
    element: <App pca={msalInstance}/>,
  },
]);

ReactDOM
  .createRoot(document.getElementById('root') as HTMLElement)
  .render(
    <StrictMode>
      <RouterProvider router={router} />
    </StrictMode>
  );
