import Blob from 'components/Blob';
import FocusTrap from 'focus-trap-react';

const Modal = ({
	isModalOpen,
	setIsModalOpen,
}: {
	isModalOpen: boolean;
	setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
	if (!isModalOpen) {
		return <></>;
	}

	return (
		<FocusTrap>
			<div
				className="
					z-50
					min-w-screen
					min-h-screen
					fixed
					flex
					justify-center
					inset-0
					outline-none
					bg-stone-900
					bg-opacity-80"
			>
				<Blob setIsModalOpen={setIsModalOpen} />
			</div>
		</FocusTrap>
	);
};

export default Modal;
