// TODO: Rename this to useRecordings.

import {
  useRef,
  useState,
  useContext,
  useCallback,
  createContext,
  PropsWithChildren,
} from 'react';
import * as Sentry from '@sentry/react';

import { useUser } from 'context';
import { apiBaseUrl } from 'helpers';

import { virtuosisAuthRequest } from 'authConfig';

type Insights = any[] | null;

interface InsightsContextTypes {
  insights: Insights;
  getInsights: (abortCtrl?: AbortController) => Promise<any>;
};

function msToTime(duration: number = 0) {
  var milliseconds = Math.floor((duration % 1000) / 100),
    seconds = Math.floor((duration / 1000) % 60),
    minutes = Math.floor((duration / (1000 * 60)) % 60),
    hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

  const h = (hours < 10) ? "0" + hours : hours;
  const m = (minutes < 10) ? "0" + minutes : minutes;
  const s = (seconds < 10) ? "0" + seconds : seconds;

  return h + ":" + m + ":" + s + "." + milliseconds;
}

const InsightsContext = createContext<InsightsContextTypes>({
  insights: null,
  getInsights() { return new Promise(() => {})},
});

export const useInsights = () => useContext(InsightsContext);

export const InsightsProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const hasInsightsInit = useRef(false);

  const [insights, setInsights] = useState<Insights>(null);

  const { getAuthToken } = useUser();

  const getInsights = useCallback(async (abortCtrl?: AbortController) => {
    try {
      const token = await getAuthToken(virtuosisAuthRequest);

      if (!token) {
        throw new Error('Failed to get auth token')
      }

      const { accessToken } = token;

      const recordingsRes = await fetch(
        `${apiBaseUrl}/user-management/v1.0/recordings?limit=200`,
        {
          signal: abortCtrl?.signal,
          headers: { Authorization: accessToken },
        },
      );

      const insightsRes = await fetch(
        `${apiBaseUrl}/insights/v1.0/insights`,
        {
          signal: abortCtrl?.signal,
          headers: { Authorization: accessToken },
        },
      );

      const recordingsData = (await recordingsRes.json()) || [];

      const insightsData = (await insightsRes.json()) || [];

      const getOverallCommScore = (
        rhythm: number | undefined,
        clarity: number | undefined,
        positiveness: number | undefined,
        speakingRate: number | undefined,
      )  => {
        if (
          rhythm === undefined
          || clarity === undefined
          || positiveness === undefined
          || speakingRate === undefined
        ) {
          return undefined;
        }
      
        return Math.round((rhythm + clarity + positiveness + speakingRate) / 4);
      };

      const getOverallScore = (
        happiness: number | undefined,
        angerFree: number | undefined,
        stressFree: number | undefined,
        anxietyFree: number | undefined,
      ) => {
        if (
          happiness === undefined
          || angerFree === undefined
          || stressFree === undefined
          || anxietyFree === undefined
        ) {
          return undefined;
        }
      
        return Math.round((happiness + angerFree + stressFree + anxietyFree) / 4);
      };

      const parseMarkers = (markers: any) => {
        const res: any = {};

        Object.keys(markers).forEach((key) => {
          const marker = markers[key] || {};

          res[key] = {
            ...marker,
            Duration: msToTime(marker["Duration"] || 0),
            Instances: (marker["Instances"] || []).map((instance: any) => {
              return {
                Start: msToTime(instance["Start"] || 0),
                End: msToTime(instance["End"] || 0),
              };
            })
          };
        });

        return res;
      };

      const data = recordingsData.map((recording: any) => {
        const insight = insightsData.find((insight: any[]) => {
          const id = (insight || [])[0]

          return id ? id === recording._id : null;
        }) || [];

        const parsed: any = {
          Insights: insight.length > 0 ? {
            Happiness: insight.at(5),
            AngerFree: insight.at(6),
            StressFree: insight.at(7),
            AnxietyFree: insight.at(8),
            OverallEmotion: getOverallScore(insight.at(5), insight.at(6), insight.at(7), insight.at(8)),
            Rhythm: insight.at(9),
            Clarity: insight.at(10),
            WordRate: insight.at(11),
            Positiveness: insight.at(12),
            FragmentRate: insight.at(13),
            SpeakingRate: insight.at(14),
            OverallCommunication: getOverallCommScore(insight.at(9), insight.at(10), insight.at(12), insight.at(14)),
          } : recording["Index"] ? "None" : "Processing...",
          Audio: recording["Index"] || "Processing...",
          MeetingStartTimeUTC: recording["StartDateTime"],
          MeetingEndTimeUTC: recording["EndDateTime"],
          MeetingDuration: msToTime(recording["Duration"] || 0),
          UploadTimeUTC: recording["CreatedAt"],
          ID: recording["_id"],
        };

        if (parsed["Audio"] && parsed["Audio"] !== "Processing...") {
          parsed["Audio"] = {
            Emotions: parseMarkers(parsed["Audio"]["Emotions"] || []),
            Sentiments: parseMarkers(parsed["Audio"]["Sentiments"] || []),
            Effects: parseMarkers(parsed["Audio"]["AudioEffects"] || []),
            Speakers: parsed["Audio"]["Speakers"] || [],
            Duration: msToTime(parsed["Audio"]["Duration"] || 0),
          }
        }

        delete parsed["_id"];
        delete parsed["UserId"]
        delete parsed["StoreRaw"];
        delete parsed["MeetingId"];
        delete parsed["MockVideoId"];
        delete parsed["OrganisationId"];

        return parsed;
      });


      if (Array.isArray(data)) {
        setInsights(data);
      } else if (!hasInsightsInit.current) {
        // This check stops insights from being cleared if the API returns null after initialization 
        setInsights([]);
      }

      hasInsightsInit.current = true;
    } catch (err) {
      if (!abortCtrl?.signal.aborted) {
        Sentry.captureException(err);
        alert('Could not get Insights data - refresh the page and try again.');
      }
    }
  }, [getAuthToken]);

  return (
    <InsightsContext.Provider value={{ insights, getInsights }}>
      {children}
    </InsightsContext.Provider>
  );
};
