import closeIcon from 'assets/images/close.png';

import Upload from "./Upload";

const Blob = ({
  setIsModalOpen,
}: {
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  return (
    <div
      className="relative my-auto container flex gap-4 flex-col"
      style={{ width: '575px', minHeight: '489px' }}
    >
      <button
        className="absolute right-6 top-6 hover:scale-110 transition"
        onClick={() => { setIsModalOpen(false); }}
      >
        <img
          width={24}
          height={24}
          src={closeIcon}
          alt="Modal close icon"
        />
      </button>
      <span className="text-2xl">
        Upload recording
      </span>
      <Upload />
    </div>
  );
};

export default Blob;
  