import { useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';

import { User } from 'types';
import { LogoutButton } from 'components/SSO';

import logo from 'assets/images/logo.webp';

const Header = ({
  UserPhoto = ''
}: {
  UserPhoto?: User["photo"]
}) => {
  const { instance } = useMsal();

  const [showLogout, setShowLogout] = useState(false);

  useEffect(() => {
    const hasLoggedInAccount = instance.getAllAccounts().length > 0;

    setShowLogout(hasLoggedInAccount);  
  }, [instance]);

  return (
    <div
      className="w-full px-20 grid items-center"
      style={{
        height: '120px',
        backgroundColor: '#181818',
        gridTemplateColumns: '178px 1fr',
      }}
    >
      <div className="grid grid-flow-col items-center gap-2.5">
        <img 
          src={logo}
          width={60}
          height={60}
          alt='Virtuosis logo'
        />
        <span
          className="text-white font-bold"
          style={{ fontSize: '22px' }}
        >
          Virtuosis
        </span>
      </div>
      <div className="flex justify-end items-center gap-5">
        {showLogout && (
          <>
            {UserPhoto && (
              <img
                width={38}
                height={38}
                className="rounded-full"
                src={UserPhoto}
                alt="User profile avatar"
              />
            )}
            <LogoutButton />
          </>
      )}
      </div>
    </div>
  );
};

export default Header;