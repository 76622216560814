import { useCallback, useRef, useState } from 'react';
import * as Sentry from '@sentry/react';
import { useUser } from 'context';
import { v4 as uuidv4 } from 'uuid';
import { newUTCString } from 'helpers';
import { type Meeting } from 'types';
import { BlobServiceClient } from '@azure/storage-blob';

import Input from 'components/Input';
import Button from 'components/Button';

import IDs from './IDs';
import New from './New';

const DEFAULT_DURATION = undefined;

const Upload = () => {
  const fileRef = useRef<null | HTMLInputElement>(null);
  const [file, setFile] = useState<null | File>(null);
  
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const DurationRef = useRef<undefined | number>(DEFAULT_DURATION)
  const MockVideoIdRef = useRef('')
  const [RecId, setRecId] = useState(uuidv4());
  const [StoreRaw, setStoreRaw] = useState<Meeting['StoreRaw']>('false');
  const [MeetingId, setMeetingId] = useState(uuidv4());

  const { UserId, OrganisationId, getSASToken } = useUser();

  const getBlobClient = useCallback(async () => {
    try {
      const sasToken = await getSASToken();

      if (!sasToken) {
        throw new Error('Missing SAS token');
      }

      const newClient = new BlobServiceClient(sasToken);

      const containers = newClient.listContainers();
  
      for await (const _ of containers) {} // eslint-disable-line

      return newClient;
    } catch (err) {
      Sentry.captureException(err);
      alert("Could not connect to Storage Blob - refresh page and try again.");
      return null;
    }
  }, [getSASToken]);

  const uploadBlob = useCallback(async () => {
    try {
      if (!file) {
        alert('Select an audio file.');
        return;
      }

      if (!DurationRef.current || DurationRef.current < 1) {
        alert('Meeting Duration is invalid.')
        return;
      }

      setMessage('');
      setIsLoading(true);

      const client = await getBlobClient();

      if (!client) {
        alert('Failed to create blob client.')
        setIsLoading(false);
        return;
      }

      const containerClient = client.getContainerClient('recordings');

      const ext = file.name.split('.').slice(-1);

      const blockBlobClient = containerClient.getBlockBlobClient(`${RecId}.${ext}`);

      const currentDate = new Date();

      const metadata: Meeting = {
        UserId,
        StoreRaw,
        OrganisationId,
        ChatId: MeetingId,
        MeetingId: uuidv4(),
        EndDateTime: newUTCString(currentDate),
        StartDateTime: newUTCString(currentDate, DurationRef.current),
      };

      if (MockVideoIdRef.current) {
        metadata.MockVideoId = MockVideoIdRef.current.replace(/[^\w]+/g, '');
      }
  
      await blockBlobClient.upload(file, file.size, { metadata: { ...metadata } });

      if (fileRef.current) {
        fileRef.current.value = '';
      }

      setFile(null);
      setRecId('');
      setMeetingId('');
      DurationRef.current = DEFAULT_DURATION;
      MockVideoIdRef.current = '';
      setMessage('Audio successfully uploaded.');
    } catch (err) {
      Sentry.captureException(err);
      alert('Upload failed - refresh page and try again.');
    }

    setIsLoading(false);
  }, [file, RecId, UserId, StoreRaw, MeetingId, OrganisationId, getBlobClient]);

  if (!MeetingId) {
    return <New
      message={message}
      setRecId={setRecId}
      setMeetingId={setMeetingId}
    />
  }

  return (
    <div className="grid gap-4">
      <hr />
      <IDs
        {...{
          RecId,
          UserId,
          OrganisationId,
        }}
      />
      <hr />
      <div className="grid gap-2">
        <label
          htmlFor="duration"
          className="text-gray-700"
        >
          Meeting Duration (seconds)
        </label>
        <Input
          id="duration"
          defaultValue={DEFAULT_DURATION}
          onChange={(e) => {
            const { target } = e || {};
            const { value } = target || {};
            const duration = parseInt(value, 10);
            DurationRef.current = duration || 0;
          }}
        />
      </div>
      {/* <hr />
      <div className="grid gap-2">
        <label
          htmlFor="mockVideoid"
          className="text-gray-700"
        >
          Mock Video ID
        </label>
        <Input
          id="mockVideoid"
          onChange={(e) => {
            const { target } = e || {};
            const { value } = target || {};
            MockVideoIdRef.current = value || '';
          }}
        />
      </div> */}
      <hr />
      <div className="grid gap-2">
        <label
          htmlFor="formFile"
          className="text-gray-700"
        >
          Recording - m4a/wav/mp3 - 100MB max
        </label>
        <input
          className="
            form-control
            block
            w-full
            px-3
            py-1.5
            text-base
            font-normal
            text-gray-700
            bg-white bg-clip-padding
            border border-solid border-gray-300
            rounded
            transition
            ease-in-out
            m-0
            focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
          type="file"
          ref={fileRef}
          id="formFile"
          onChange={(e) => {
            const files = e.target.files || new FileList();
            const file = files[0];

            if (!file || !fileRef.current) {
              setFile(null);
              return;
            }

            if (file.type !== 'audio/x-m4a' && file.type !== 'audio/wav' && file.type !== 'audio/mpeg') {
              alert("Unsupported file type.");
              fileRef.current.value = '';
              setFile(null);
              return;
            }

            if ((file.size || 0) > 100000000) {
              alert("File is too large.");
              fileRef.current.value = '';
              setFile(null);
              return;
            }

            setFile(file);
          }}
        />
      </div>
      {/* <hr />
      <div className="flex gap-3 items-center mb-0.5">
        <label className="inline-flex relative cursor-pointer">
          <input
            value=""
            type="checkbox"
            disabled={false}
            defaultChecked={false}
            className="sr-only peer"
            onChange={(e) => setStoreRaw(e.target?.checked ? 'true' : 'false')}
          />
          <div className="w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all peer-checked:bg-blue-600"></div>  
        </label>
        <span className="text-gray-700 cursor-default">Store audio file & indexer JSON</span>
      </div> */}
      <Button
        title="End meeting"
        onClick={uploadBlob}
        isLoading={isLoading}
        loadingWidth='111px'
        style={{ justifySelf: 'start' }}
      />
    </div>
  );
};

export default Upload;
